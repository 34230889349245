import { render, staticRenderFns } from "./WithLogo.vue?vue&type=template&id=73bf6b74&"
import script from "./WithLogo.vue?vue&type=script&lang=js&"
export * from "./WithLogo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsImageSimple: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Elements/Image/Simple.vue').default,PrismicLinkComponent: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Prismic/LinkComponent/index.vue').default,ElementsIconRounded: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Elements/Icon/Rounded.vue').default})
