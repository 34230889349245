
import themeMixin from '@/mixins/theme'

export default {
  name: 'RoundedIcon',
  mixins: [themeMixin],
  props: {
    theme: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: true,
      default: '',
    },
    alt: {
      type: String,
      required: true,
      default: '',
    },
  },
  computed: {
    backgroundColor() {
      if (!this.theme) {
        return ''
      }

      return this.getAccessibilityContrast(this.theme)
    },
    contrast() {
      return this.getContrastColor(this.theme)
    },
  },
}
