
import { Hooper, Slide } from 'hooper'
import themeMixin from '~/mixins/theme'

export default {
  name: 'CarouselWithLogo',
  components: {
    Hooper,
    Slide,
  },
  mixins: [themeMixin],
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    itemsToShow: {
      type: Number,
      required: true,
      default: 4,
    },
    itemsToShowMobile: {
      type: Number,
      required: true,
      default: 3.5,
    },
    showItemsCounter: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      currentSlide: 0,
      currentSlideFromHooper: 0,
    }
  },
  computed: {
    hooperSettings() {
      return {
        itemsToShow: this.visibleItems,
        centerMode: false,
        trimWhiteSpace: true,
        wheelControl: false,
      }
    },
    isLastSlide() {
      const total = this.items.length
      const difference = total - this.visibleItems

      return difference === this.currentSlideFromHooper
    },
    rightControlTheme() {
      if (this.isLastSlide) {
        return {
          bg: '',
          icon: `arrow-right-${this.themeIconContrast}`,
        }
      }

      return {
        bg: 'sky-blue',
        icon: 'arrow-right-dark',
      }
    },
    leftControlTheme() {
      if (this.currentSlideFromHooper === 0) {
        return {
          bg: '',
          icon: `arrow-left-${this.themeIconContrast}`,
        }
      }

      return {
        bg: 'sky-blue',
        icon: 'arrow-left-dark',
      }
    },
    showControls() {
      return this.items?.length > this.visibleItems
    },
    visibleItems() {
      const isMobile = this.$breakpoints.sSm
      if (isMobile) return this.itemsToShowMobile
      return this.itemsToShow
    },
  },

  methods: {
    updateCarousel(payload) {
      this.currentSlideFromHooper = payload.currentSlide
      this.currentSlide =
        this.visibleItems + Math.ceil(this.currentSlideFromHooper) - 1
    },
    slidePrev() {
      this.$refs.carousel.slidePrev()
    },
    slideNext() {
      this.$refs.carousel.slideNext()
    },
  },
}
